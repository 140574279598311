import React from "react";
import ReactDOM from "react-dom";
import { Element } from "react-scroll";


// reactstrap components
import {
  Card,
  Button,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import SectionCarousel from "components/SectionCarousel/SectionCarousel.js";
import EmailForm from "components/SendEmail/EmailForm";
// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/demo/demo.css?v=1.3.0";

ReactDOM.render(
  <div>
    <IndexNavbar />
    <LandingPageHeader />
    <div className="main">
      <div className="section text-center">
{/*         <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="3">
              <Element name="about_us" className="text-center h2">Rólunk</Element>
              <h5 className="description">
                Bemutatkozó szöveg helye
              </h5>
              <br />
              <Button
                className="btn-round"
                color="info"
                href="#about_us_more"
                onClick={(e) => e.preventDefault()}
              >
                További részletek
              </Button>
            </Col>
          </Row>
          <br />
          <br />
        </Container> */}
        <Container>
          <Element name="our_work" className="text-center h2">Munkáink</Element>
          <SectionCarousel/>
        </Container>
      </div>
      <div className="section landing-section">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <Element name="contact_us" className="text-center h2">Kapcsolat</Element>

              <EmailForm />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    {/* <DemoFooter /> */}
    </div>,
  document.getElementById('root')
);


