import { flexbox } from "@mui/system";
import SectionCarousel from "components/SectionCarousel/SectionCarousel";
import React, { useEffect } from "react";
import {
    Button,
    Modal,
    Row,
    Col,
} from "reactstrap";
import {defaultIcon, gipszIcon, villanyIcon, boilerIcon, kazanIcon, hoszivIcon, komuvesIcon, burkolasIcon, festesIcon, asztalosIcon} from "components/SectionCarousel/carouselImgConst.js";
import {
    gipsz, asztalos, festes, boiler, hossziv, villany
  } from "./descriptionsConst.js"



function OpenModal(props) {

    const [modal, setModal] = React.useState(false);
    const toggleModal = () => {
        setModal(!modal);
    };
    let img = { src: "",
                altText: "",
                caption: "" };
    let description = "";
   
        switch(props.id) {
            case 1:
            img = villanyIcon;
            description = villany
            break;
            case 2:
            img = boilerIcon;
            description = boiler
            break;
            case 3:
            img = kazanIcon;
            break;
            case 4:
            img = hoszivIcon;
            description = hossziv
            break;
            case 5:
            img = komuvesIcon;
        
            break;
            case 6:
            img = burkolasIcon;
            break;
            case 7:
            img = gipszIcon;
            description = gipsz
            break;
            case 8:
            img = festesIcon;
            description = festes
            break;
            case 9:
            img = asztalosIcon;
            description = asztalos
            break;
            default:
            img = defaultIcon;
            break;
        }

    

    return (
        <Row id="modals">
            <Col md="6"                 
                style={{
                display: 'block',
                margin: 'auto'}}>
                {/* Button trigger modal */}
                <a href="#modal" onClick={toggleModal}>
                <div className="card-avatar">
                   
                   <img
                     src={img.src}
                   />
               </div>
               </a>
                {/* Modal */}
                <Modal size="xl" isOpen={modal} toggle={toggleModal}>
                    <div className="modal-header">
                        <h5 style={{justifyContent: "left", color: "black"}}>
                            {img.caption}
                        <button
                            aria-label="Close"
                            className="close"
                            type="button"
                            onClick={toggleModal}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                        </h5>
                      </div>
                    <div className="modal-body" style={{color: "black", fontWeight: "bold"}}>
                        <Row>
                            <Col xs={12} lg={6}><SectionCarousel id={props.id}></SectionCarousel></Col>
                            <Col>{description}</Col>
                        </Row>
                    </div>
                </Modal>
            </Col>
        </Row>
    );
}

export default OpenModal;
