const villany = 
    <ul type="none">
    <li>Négylakásos társasházig elektromos hálózat tervezése, kivitelezése </li>
    <li>Mérőóra hely kiépítés </li>
    <li>Elektromos hálózat felújítása, bővítése (Amperbővítés) </li>
    <li>Fí relé beépítés (AVK) </li>
    <li>Automata kaputelepítés, felprogramozás, karbantartás </li>
    <li>Kaputelefon telepítés, karbantartás </li>
    <li>Sütő, főzőlap beépítés/beüzemelés </li>
    <li>EPH hálózat kiépítés jegyzőkönyvezés </li>
    <li>Riasztó és kamerarendszerek kiépítése </li>
    </ul>
const hossziv =
    <ul type="none">
    <li>Hőszivattyú rendszer tervezése, kivitelezése, áttervezése, felújítása</li>
    <li>Fűtő-hűtő rendszer kiépítése működtetése </li>
    <li>Hőszívattyú kazán hibrid rendszerek kiépítése, beüzemelése</li>
    </ul>
const boiler =
    <ul type="none">
    <li>Hajdú/Ariston/Aquastic bojlerek szakszerű szerelése vízkőtelenítése, tisztítása, savazása</li>
    <li>Más márkájú bojlerek javtíása, vízkőtelenítése, tisztítása, savazása</li>
    <li>Bojlerek villamosvédelmének kiépítése, nyomáscsökkentő kiépítése, cseréje</li>
    </ul>
const asztalos =
    <ul type="none">
    <li>Bútorok szerelése, átépítése, elektromos hálózat beépítése, egyedi világítás beépítése</li>
    <li>Sütő, főzőlap beépítés/beüzemelés </li>
    <li>Rejtett elosztó beépítése</li>
    </ul>
const festes =
    <ul type="none">
    <li>Felfelület előkészítése, glettelése, élvédőzése</li>
    <li>Stukkó készítése</li>
    <li>Stukkóba rejtett világítás</li>
    <li>Tapétázás</li>
    <li>Festés</li>
    </ul>
const gipsz =
    <ul type="none">
    <li>Acél és favázzal</li>
    <li>OSB, gipszrost,gipszkarton, MDF lap, rétegelt lemezzel</li>
    <li>Oldalfal, mennyezet vizes helyiség gipszkartonozása</li>
    <li>Rejtett világítás, elektromos hálózat beépítése</li>
    <li>Egyedi alakzatok kialakítása</li>
    </ul>
export{gipsz, asztalos, festes, boiler, hossziv, villany}