import React from "react";
// Images for carousel
import {
  defaultItems, 
  electricityItems, 
  boilerItems, 
  kazanItems, 
  hosszivItems, 
  komuvesItems,
  burkolasItems, 
  gipszItems, 
  festesItems, 
  asztalosItems
} from "./carouselImgConst.js";

// reactstrap components
import {
  Card,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption
} from "reactstrap";


function SectionCarousel(props) {
  let items = [];
  
  switch (props.id) {
    case 1:
      items = electricityItems;
      break;
    case 2:
      items = boilerItems;
      break;
    case 3:
      items = kazanItems;
      break;
    case 4:
      items = hosszivItems;
      break;
    case 5:
      items = komuvesItems;
      break;
    case 6:
      items = burkolasItems;
      break;
    case 7:
      items = gipszItems;
      break;
    case 8:
      items = festesItems;
      break;
    case 9:
      items = asztalosItems;
      break;
    default:
      items = defaultItems;
      break;
  }
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div className="section-carousel pt-o" id="carousel">
        <Container>
          <Row>
            {/* md = 8 a méretet állítja, nem a pozit,*/}
            <Col row-md="6" col-md="6" >
              <Card className="page-carousel">
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                >
                  <CarouselIndicators
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items.map((item) => {
                    return (
                      <CarouselItem class="carousel-item"
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                      <div className="carousel-img">
                        <img style={{ marginLeft: "auto", marginRight: "auto", display: "flex", 
                        justifyContent: "center", maxHeight: '70vh', width: 'auto'}} src={item.src} alt={item.altText} />
                      </div>
                        <CarouselCaption
                          captionText={item.caption}
                          captionHeader=""
                        />
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="left carousel-control carousel-control-prev"
                    data-slide="prev"
                    onClick={(e) => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="right carousel-control carousel-control-next"
                    data-slide="next"
                    onClick={(e) => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </a>
                </Carousel>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionCarousel;
