/*!

=========================================================
* Paper Kit React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
import { Link as ScrollLink, animateScroll } from "react-scroll";


// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Button
} from "reactstrap";
import "./styles.scss"
function scrollToTop() {
  animateScroll.scrollToTop();
}

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 99 ||
        document.body.scrollTop > 99
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 100 ||
        document.body.scrollTop < 100
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar
      className={classnames("fixed-top", navbarColor)}
      color-on-scroll="100"
      expand="lg"
    >
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            onClick={ scrollToTop }
            data-placement="bottom"
            title="Horváth és Társa"
           
          >
            Horváth és Társa
            <br/>
            <small>Tel.: +36 70 315 1993</small>
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
{/*             <NavItem>
              <ScrollLink className="nav-link" activeClass="active" to="about_us" spy={true} smooth={true} offset={-100} duration={500}>               
                  <i className="nc-icon nc-single-02" /> Rólunk                
              </ScrollLink>
            </NavItem> */}
            <NavItem>
             <ScrollLink className="nav-link" activeClass="active" to="our_work" spy={true} smooth={true} offset={-100} duration={500}> 
                <i className="nc-icon nc-album-2" /> Munkáink
              </ScrollLink>
            </NavItem>
            <NavItem>
              <ScrollLink className="nav-link" activeClass="active" to="contact_us" spy={true} smooth={true} offset={-100} duration={500}> 
                <i className="nc-icon nc-chat-33" />Kapcsolat
              </ScrollLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;
