/*!

=========================================================
* Paper Kit React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import OpenModal from "views/index-sections/OpenModal";
// reactstrap components
import {
  Card,
  Button,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import bg from "assets/img/headerbg.svg"

function LandingPageHeader() {
  let pageHeader = React.createRef();

  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + bg + ")",
        }}
        className="page-header align-items-stretch pt-4"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="filter" />
        <Container className="fluid">
          <Row>
            <div className="motto text-center">
              <br />
              <br />
              <h1>Felújítjuk otthonát</h1>
              <h3>Vállaljuk lakóházak  víz-,fűtés- és villanyszerelését, burkolását, kőműves, festési és gipszkarton munkák garanciális kivitelezését</h3>
              <br />
              <br />
            </div>
            <Col xs={{ size: 6 }} sm={{ size: 4 }} >
              <div className="info">
                <Card className="card-profile card-plain">
                  <OpenModal id={1} ></OpenModal>
                  <div className="description">
                    <h5 className="info-title">Villanyszerelés</h5>
                  </div>
                </Card>
              </div>
            </Col>
            <Col xs={{ size: 6 }} sm={{ size: 4 }}>
              <div className="info">
                <Card className="card-profile card-plain">
                  <OpenModal id={2} ></OpenModal>
                  <div className="description">
                    <h5 className="info-title">Bojlerszerelés</h5>
                  </div>
                </Card>
              </div>
            </Col>
            <Col xs={{ size: 6 }} sm={{ size: 4 }}>
              <div className="info">
                <Card className="card-profile card-plain">
                  <OpenModal id={3} ></OpenModal>
                  <div className="description">
                    <h5 className="info-title">Kazánfelújítás</h5>
                  </div>
                </Card>
              </div>
            </Col>
            <Col xs={{ size: 6 }} sm={{ size: 4 }}>
              <div className="info">
                <Card className="card-profile card-plain">
                  <OpenModal id={4} ></OpenModal>
                  <div className="description">
                    <h5 className="info-title">Hőszivattyú</h5>
                  </div>
                </Card>
              </div>
            </Col>
            <Col xs={{ size: 6 }} sm={{ size: 4 }}>
              <div className="info">
                <Card className="card-profile card-plain">
                  <OpenModal id={5} ></OpenModal>
                  <div className="description">
                    <h5 className="info-title">Kőműves munkák</h5>
                  </div>
                </Card>
              </div>
            </Col>
            <Col xs={{ size: 6 }} sm={{ size: 4 }}>
              <div className="info">
                <Card className="card-profile card-plain">
                  <OpenModal id={6} ></OpenModal>
                  <div className="description">
                    <h5 className="info-title">Burkolás</h5>
                  </div>
                </Card>
              </div>
            </Col>
            <Col xs={{ size: 6 }} sm={{ size: 4 }}>
              <div className="info">
                <Card className="card-profile card-plain">
                  <OpenModal id={7} ></OpenModal>
                  <div className="description">
                    <h5 className="info-title">Gipszkartonozás</h5>
                  </div>
                </Card>
              </div>
            </Col>
            <Col xs={{ size: 6 }} sm={{ size: 4 }}>
              <div className="info">
                <Card className="card-profile card-plain">
                  <OpenModal id={8} ></OpenModal>
                  <div className="description">
                    <h5 className="info-title">Festés</h5>
                  </div>
                </Card>
              </div>
            </Col>
            <Col xs={{ size: 6 }} sm={{ size: 4 }}>
              <div className="info">
                <Card className="card-profile card-plain">
                  <OpenModal id={9} ></OpenModal>
                  <div className="description">
                    <h5 className="info-title">Asztalos munkák</h5>
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
