//Carousel ikonjai
const villanyIcon = { src: require("assets/img/about_us/villany.png"),
                    altText: "",
                    caption: "Villanyszerelés A-Z-ig" 
                  };
const gipszIcon = { src: require("assets/img/about_us/gipszkarton.png"),
                    altText: "",
                    caption: "Gipszkartonozás" 
                  };
const boilerIcon = { src: require("assets/img/about_us/boiler.png"),
                  altText: "",
                  caption: "Bojlerszerelés" 
                };
const kazanIcon = { src: require("assets/img/about_us/kazan.png"),
                  altText: "",
                  caption: "Kazánfelújítás" 
                };
const defaultIcon = { src: require("assets/img/about_us/electric.jpg"),
                      altText: "",
                      caption: "Villanyszerelés" 
                    };
const hoszivIcon = { src: require("assets/img/about_us/hosziv.png"),
                    altText: "",
                    caption: "Hőszivattyú" 
                  };
const komuvesIcon = { src: require("assets/img/about_us/komuves.png"),
                    altText: "",
                    caption: "Kőműves munkák" 
                  };
const burkolasIcon = { src: require("assets/img/about_us/burkolas.png"),
                  altText: "",
                  caption: "Burkolás" 
                };
const festesIcon = { src: require("assets/img/about_us/festes.png"),
                altText: "",
                caption: "Festés" 
              };
const asztalosIcon = { src: require("assets/img/about_us/asztalos.png"),
              altText: "",
              caption: "Asztalos munkák" 
            };
// főoldal referencia képei
const defaultItems = [
    {
      src: require("assets/img/reference/1.jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/reference/2.jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/reference/3.jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/reference/4.jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/reference/5.jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/reference/6.jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/reference/7.jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/reference/8.jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/reference/9.jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/reference/10.jpg"),
      altText: "",
      caption: ""
    },
  
  ];
  //Villanyszerelés
  const electricityItems = [
    {
      src: require("assets/img/01_Villany/ec (1).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (2).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (3).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (4).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (5).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (6).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (7).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (8).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (9).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (10).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (11).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (12).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (13).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (14).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (15).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (16).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (17).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (18).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (19).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (20).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (21).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (22).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (23).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (24).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (25).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (26).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (27).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (28).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (29).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (30).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (31).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (32).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (33).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (34).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (35).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (36).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (37).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (38).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (39).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (40).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (41).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (42).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (43).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/01_Villany/ec (44).jpg"),
      altText: "",
      caption: ""
    },
  
  ];
  
  //Bojlerszerelés
  
  const boilerItems = [
    {
      src: require("assets/img/02_Bojler/boiler (1).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/02_Bojler/boiler (2).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/02_Bojler/boiler (3).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/02_Bojler/boiler (4).jpg"),
      altText: "",
      caption: ""
    },
  ];
  //Kazánfelújítás
  const kazanItems = [
    {
      src: require("assets/img/03_Kazan/kazan (1).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/03_Kazan/kazan (2).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/03_Kazan/kazan (3).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/03_Kazan/kazan (4).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/03_Kazan/kazan (5).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/03_Kazan/kazan (6).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/03_Kazan/kazan (7).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/03_Kazan/kazan (8).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/03_Kazan/kazan (9).jpg"),
      altText: "",
      caption: ""
    },
  ];
  //Hőszivattyú
  const hosszivItems = [
    {
      src: require("assets/img/04_Hoszivattyu/ho (1).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/04_Hoszivattyu/ho (2).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/04_Hoszivattyu/ho (3).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/04_Hoszivattyu/ho (4).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/04_Hoszivattyu/ho (5).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/04_Hoszivattyu/ho (6).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/04_Hoszivattyu/ho (7).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/04_Hoszivattyu/ho (8).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/04_Hoszivattyu/ho (9).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/04_Hoszivattyu/ho (10).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/04_Hoszivattyu/ho (11).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/04_Hoszivattyu/ho (12).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/04_Hoszivattyu/ho (13).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/04_Hoszivattyu/ho (14).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/04_Hoszivattyu/ho (15).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/04_Hoszivattyu/ho (16).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/04_Hoszivattyu/ho (17).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/04_Hoszivattyu/ho (18).jpg"),
      altText: "",
      caption: ""
    },
  ];
  //Kőműves munkak
  const komuvesItems = [
    {
      src: require("assets/img/05_Komuves/ko (1).jpg"),
      altText: "",
      caption: ""
    },
  ];
  //Burkolás
  const burkolasItems = [
    {
      src: require("assets/img/06_Burkolas/burkolas (1).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/06_Burkolas/burkolas (2).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/06_Burkolas/burkolas (3).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/06_Burkolas/burkolas (4).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/06_Burkolas/burkolas (5).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/06_Burkolas/burkolas (6).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/06_Burkolas/burkolas (7).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/06_Burkolas/burkolas (8).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/06_Burkolas/burkolas (9).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/06_Burkolas/burkolas (10).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/06_Burkolas/burkolas (11).jpg"),
      altText: "",
      caption: ""
    },
  ];
  //Gipszkartonozás
  const gipszItems = [
    {
      src: require("assets/img/07_Gipszkarton/gipsz (1).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/07_Gipszkarton/gipsz (2).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/07_Gipszkarton/gipsz (3).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/07_Gipszkarton/gipsz (4).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/07_Gipszkarton/gipsz (5).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/07_Gipszkarton/gipsz (6).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/07_Gipszkarton/gipsz (7).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/07_Gipszkarton/gipsz (8).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/07_Gipszkarton/gipsz (9).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/07_Gipszkarton/gipsz (10).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/07_Gipszkarton/gipsz (11).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/07_Gipszkarton/gipsz (12).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/07_Gipszkarton/gipsz (13).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/07_Gipszkarton/gipsz (14).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/07_Gipszkarton/gipsz (15).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/07_Gipszkarton/gipsz (16).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/07_Gipszkarton/gipsz (17).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/07_Gipszkarton/gipsz (18).jpg"),
      altText: "",
      caption: ""
    },
  ];
  // Festés
  const festesItems = [
    {
      src: require("assets/img/08_Festes/fest (1).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/08_Festes/fest (2).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/08_Festes/fest (3).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/08_Festes/fest (4).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/08_Festes/fest (5).jpg"),
      altText: "",
      caption: ""
    },
  ];
  // Asztalos munkák
  
  const asztalosItems = [
    {
      src: require("assets/img/09_Asztalos/asztalos (1).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/09_Asztalos/asztalos (2).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/09_Asztalos/asztalos (3).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/09_Asztalos/asztalos (4).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/09_Asztalos/asztalos (5).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/09_Asztalos/asztalos (6).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/09_Asztalos/asztalos (7).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/09_Asztalos/asztalos (8).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/09_Asztalos/asztalos (9).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/09_Asztalos/asztalos (10).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/09_Asztalos/asztalos (11).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/09_Asztalos/asztalos (12).jpg"),
      altText: "",
      caption: ""
    },
    {
      src: require("assets/img/09_Asztalos/asztalos (13).jpg"),
      altText: "",
      caption: ""
    },
  
  ];

  export {defaultItems, electricityItems, boilerItems, kazanItems, hosszivItems, komuvesItems, burkolasItems, gipszItems, festesItems, 
    asztalosItems, gipszIcon, defaultIcon, villanyIcon, boilerIcon, kazanIcon, hoszivIcon, komuvesIcon, burkolasIcon, festesIcon, asztalosIcon};