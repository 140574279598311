import React, { useRef, useState } from "react";
import {
  Button,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Alert
} from "reactstrap";
import RECAPTCHA from "react-google-recaptcha";

const error = {
  recaptchatokenmissing: "Kérem erősítse meg, hogy nem robot",
  unsuccessfulsending: "Az email küldése sikertelen",
  successfulsending: "Az emailt sikeresen elküldtük"
}

const EmailForm = () => {
  const recaptchaRef = React.createRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errormessage, setErrorMessage] = useState("");
  const [alerthidden, setHidden] = useState(true);
  const [severity, setSeverity] = useState("");
  const [color, setColor] = useState("");

  const onSubmitWithReCAPTCHA = async (event) => {
    event.preventDefault()
    const recaptchaToken = recaptchaRef.current.getValue();
    console.log(recaptchaToken)
    if (recaptchaToken) {
      const formData = new FormData();
      formData.append("replyto", "orshe1993@gmail.com")
      formData.append("name", name)
      formData.append("email", email)
      formData.append("message", message)

      formData.append("access_key", process.env.REACT_APP_EMAIL_FORM_KEY);

      const object = Object.fromEntries(formData);
      const json = JSON.stringify(object);

      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: json
      }).then((res) => res.json());

      if (res.success) {
        setHidden(false);
        setSeverity("success");
        setErrorMessage(error.successfulsending);
        setColor("success");
        console.log(severity);
      }
      else{
        setHidden(false);
        setSeverity("error");
        setErrorMessage(error.unsuccessfulsending);
        console.log(severity);
        setColor("danger");
      }
    }
    else {
      setErrorMessage(error.recaptchatokenmissing);
      setHidden(false);
      setSeverity("error");
      setColor("danger");
      console.log(severity);
    }
    
  }
  return (
    <Form className="contact-form" onSubmit={onSubmitWithReCAPTCHA}>
      <Row>
        <Col md="6">
          <label>Név</label>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="nc-icon nc-single-02" />
              </InputGroupText>
            </InputGroupAddon>
            <Input placeholder="Név" type="text" name="name" required value={name} onChange={(event) => setName(event.target.value)} />
          </InputGroup>
        </Col>
        <Col md="6">
          <label>Email</label>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="nc-icon nc-email-85" />
              </InputGroupText>
            </InputGroupAddon>
            <Input placeholder="Email" type="text" name="email" required value={email} onChange={(event) => setEmail(event.target.value)} />
          </InputGroup>
        </Col>
      </Row>
      <label>Üzenet</label>
      <Input
        placeholder="Mivel kapcsolatban segíthetünk Önnek?"
        type="textarea"
        rows="4"
        required
        value={message}
        onChange={(event) => setMessage(event.target.value)}
      />
      <Row>
        <Col className="ml-auto mr-auto" md="4">
          <Alert variant="outlined" hidden={alerthidden} severity={severity} color={color} >{errormessage}</Alert>
          <RECAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY}
            ref={recaptchaRef}
          />
          <Button className="btn-fill" color="danger" size="lg" type="submit">
            Üzenet elküldése
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
export default EmailForm;